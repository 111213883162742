import { Component, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";
import moment from "moment";
import DfStore from "@/vue/domain/store/df-store";
import DfProduct from "@/vue/domain/product/df-product";
import DfPromotion from "@/vue/domain/promotion/df-promotion";

const DfCardProductComponent = () => Utils.externalComponent2("df-card-product");
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");
const DfRecipeProductsComponent = () => Utils.externalComponent2("df-recipe-products");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfCardProduct: DfCardProductComponent,
    DfRecipeProducts: DfRecipeProductsComponent,
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfPageRecipeComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get recipe(): DfContent {
    return this.recipes.length ? this.recipes.find((recipe: DfContent) => recipe.alias === this.$route.params.recipeAlias) : null;
  }

  get recipes(): Array<DfContent> {
    const storeRecipe: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "RCP");
    const brandRecipe: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "RCP");
    const promotionRecipe: Array<DfContent> = this.$store.getters.promotionContents.filter((content: DfContent) => content.type.code === "RCP");
    const recipes: Array<DfContent> = [...storeRecipe, ...brandRecipe, ...promotionRecipe].sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));

    return recipes;
  }

  get recipePreview(): string {
    return Utils.getPropertyImageUrl(this.recipe, Utils.PROPERTY_PREVIEW, null);
  }

  get recipeTitle(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_TITLE, "STRING");
  }

  get recipeText(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_TEXT, "TEXT");
  }

  get recipeDate(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_DATE, "DATE");
  }

  get difficultyLevel(): string {
    const difficultyLevel: any = Utils.getPropertyValue(this.recipe, Utils.PROPERTY_DIFFICULTY_LEVEL, "LIST");
    return difficultyLevel ? difficultyLevel.label : null;
  }

  get preparationTime(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_PREPARATION_TIME, "NUMBER");
  }

  get cookingTime(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_COOKING_TIME, "NUMBER");
  }

  get servings(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_SERVINGS, "NUMBER");
  }

  get ingredients(): string {
    return Utils.getPropertyValues(this.recipe, Utils.PROPERTY_INGREDIENTS, "STRING");
  }

  get recipeInformationsCount(): number {
    let informationsCount = 0;
    if (this.difficultyLevel) {
      informationsCount++;
    }
    if (this.preparationTime) {
      informationsCount++;
    }
    if (this.cookingTime) {
      informationsCount++;
    }
    if (this.servings) {
      informationsCount++;
    }
    return informationsCount;
  }

  get recipeInformationsGridColumnClass(): string {
    switch (this.recipeInformationsCount) {
      case 1:
        return "df-grid-cols-1";
      case 2:
        return "df-grid-cols-2";
      case 3:
        return "df-grid-cols-3";
      case 4:
        return "df-grid-cols-4";
      default:
        return "";
    }
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  get recipeProductsUniqueId(): Array<string> {
    return this.recipe ? this.recipe.products : [];
  }

  get promotionAlias(): string {
    return this.currentPromotion.alias;
  }

  get recipeStyle(): any {
    const style: any = {};
    if (this.recipePreview) {
      style.backgroundImage = `url('${this.recipePreview}')`;
    }

    return style;
  }

  get showBreadcrumb(): boolean {
    return Utils.showBreadcrumb();
  }

  @Watch("recipe", { immediate: true })
  recipeChange() {
    document.title = this.recipeTitle ? `${this.recipeTitle} | ${Utils.APP_NAME}` : "";
  }
}
